<template>
    <div>
        <v-app>
            <div class="row">
                <div class="col">
                    <v-card>
                        <v-card-title>
                            Ordenes de compra
                            <v-spacer></v-spacer>
                            <v-btn class="" @click="fetchDataPurchaseOrders"  text icon color="blue">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <h3 class="title">Seleccione una orden de compra para ingresar/modificar productos:</h3>
                            <div class="datepicker-trigger">
                                <v-text-field
                                    
                                    id="datepicker-trigger"
                                    placeholder="Filtrar por rango de fechas"
                                    :value="formatDates(dateOne, dateTwo)"
                                />
                                <AirbnbStyleDatepicker
                                    :trigger-element-id="'datepicker-trigger'"
                                    :mode="'range'"
                                    :fullscreen-mobile="true"
                                    :date-one="dateOne"
                                    :date-two="dateTwo"
                                    @date-one-selected="val => { dateOne = val }"
                                    @date-two-selected="val => { dateTwo = val }"
                                />
                            </div>
                            <v-data-table
                                :headers="headers"
                                :items="tableData"
                                :search="search"
                                :single-select="true"
                                show-select
                                v-model="selected"
                                no-results-text="No hay resultados disponibles"
                                no-data-text="No hay datos disponibles"
                                loading-text="Cargando datos..."
                                :sort-by="'id'"
                                :sort-desc="true"
                                class="elevation-1 datatable"
                            >
                                <template v-slot:item.ingreso="{ item }">
                                    <td class="text-center">
                                        {{ $moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a') }}
                                    </td>
                                </template>
                                <template v-slot:item.acciones="{ item }">
                                    <td class="text-center">
                                        <v-btn @click="downloadPdfAComprar(item.id)" small color="primary" dark class="mr-2"
                                        ><v-icon left dark>mdi-download</v-icon>PDF a comprar</v-btn>
                                        <v-btn v-if="item.hasPurchasedProducts" @click="downloadPdfCompras(item.id)" small color="success"  
                                        ><v-icon left dark>mdi-download</v-icon>PDF compras</v-btn>
                                        <v-btn  :href="'#/oc/'+item.purchase_order_reference" small color="success"  
                                        ><v-icon left dark>mdi-file</v-icon>Ver detalles</v-btn>
                                    </td>
                                </template>
                                <template v-slot:item.estado="{ item }">
                                    <td class="text-center">
                                        <b-button size="sm" v-if="item.estado == 'Envasando'" pill variant="info">Envasando</b-button>
                                        <b-button size="sm" v-if="item.estado == 'Envasado'" pill variant="success">Envasado</b-button>
                                    </td>
                                </template>
                            </v-data-table>
                            <br><br>
                            <v-btn @click="startIngresarItems" v-show="selected[0] && selected[0].estado != 'Envasado'" small color="info" dark class="float-left"
                            ><v-icon left dark>mdi-format-list-checks</v-icon>Ingresar/editar productos comprados</v-btn>
                            <br><br>

                            <v-container v-show="itemsOrden.length && mostrar == 1">
                                <h3 class="title">Ingrese items en cada producto:</h3>
                                <v-data-table
                                    :headers="[
                                        {text:'Proveedor',value:'supplier', width: '180px'},
                                        {text:'Producto',value:'name', width: '100px'},
                                        {text:'Cantidad comprada',value:'quantity', width: '150px'},
                                        {text:'Cantidad requerida',value:'required', width: '140px'},
                                        {text:'Precio',value:'price', width: '200px'},
                                        {text:'N° factura', value:'invoice_number', width: '100px'},
                                        {text:'N° OC',value:'delivery_receipt', width: '100px'},
                                        {text:'Formato mercadería',value:'merchandise_format', width: '100px'},
                                        {text:'Acciones',value:'acciones'}
                                    ]"
                                    :items="itemsOrden"
                                    no-results-text="No hay resultados disponibles"
                                    no-data-text="No hay datos disponibles"
                                    loading-text="Cargando datos..."
                                >
                                    <template v-slot:item.supplier="{item}">
                                        <td class="text-center">
                                            <v-combobox
                                                v-model="item.supplier"
                                                :items="suppliersList"
                                                item-value="value"
                                                label="Proveedor"
                                            ></v-combobox>
                                            <!--
                                            <v-select
                                                :items="suppliersList"
                                                v-model="item.supplier"
                                                label="Proveedor"
                                            ></v-select>
                                            -->
                                        </td>
                                    </template>
                                    <template v-slot:item.required="{item}">
                                        <td class="text-center">
                                            {{item.required}} {{item.unit}}
                                        </td>
                                    </template>
                                    <template v-slot:item.quantity="{item}">
                                        <td class="text-center">
                                            <v-text-field autocomplete="off" :suffix="item.unit" type="number" min="0" step="0.001" v-model="item.quantity"></v-text-field>
                                        </td>
                                    </template>
                                    <template v-slot:item.price="{item}">
                                        <td class="text-center">
                                            <v-text-field
                                                v-model="item.price"
                                                type="number"
                                                min="0"
                                                step="1"
                                                required
                                                :suffix="'Pesos'"
                                                autocomplete="off"
                                            ></v-text-field>
                                        </td>
                                    </template>
                                    <template v-slot:item.invoice_number="{item}">
                                        <td class="text-center">
                                            <v-text-field autocomplete="off" v-model="item.invoice_number"></v-text-field>
                                        </td>
                                    </template>
                                    <template v-slot:item.delivery_receipt="{item}">
                                        <td class="text-center">
                                            <v-text-field autocomplete="off" v-model="item.delivery_receipt"></v-text-field>
                                        </td>
                                    </template>
                                    <template v-slot:item.merchandise_format="{item}">
                                        <td class="text-center">
                                            <v-text-field autocomplete="off" v-model="item.merchandise_format"></v-text-field>
                                        </td>
                                    </template>
                                    <template v-slot:item.acciones="{item}">
                                        <td class="text-center">
                                            <v-btn @click="eliminarFila(item)" text icon color="red">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                </v-data-table>
                                <br>
                                <h3 class="title">Adicionales</h3>
                                <v-form >
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        md="3"
                                        >
                                            <v-combobox
                                                :items="suppliersList"
                                                v-model="newCanastaItem.supplier_id"
                                                label="Proveedor"
                                                ref="vselect"
                                                :disabled="!newCanastaItem.product"
                                            ></v-combobox>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="3"
                                        >
                                            <v-select
                                                :items="itemsOrden.map(e => ({text: e.name, value: e}))"
                                                v-model="newCanastaItem.product"
                                                label="Producto"
                                                required
                                            ></v-select>
                                        </v-col>

                                        <v-col
                                        cols="12"
                                        md="3"
                                        >
                                        <v-text-field
                                            v-model="newCanastaItem.quantity"
                                            type="number"
                                            min="0"
                                            step="0.05"
                                            label="Cantidad comprada"
                                            required
                                            :disabled="!newCanastaItem.product"
                                            :suffix="newCanastaItem.product ? newCanastaItem.product.unit : ''"
                                            autocomplete="off"
                                        ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="3"
                                        >
                                        <v-text-field
                                            v-model="newCanastaItem.price"
                                            type="number"
                                            min="0"
                                            step="1"
                                            label="Precio"
                                            required
                                            :suffix="'Pesos'"
                                            :disabled="!newCanastaItem.product"
                                            autocomplete="off"
                                        ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        md="3"
                                        >
                                            <v-text-field
                                                v-model="newCanastaItem.invoice_number"
                                                label="N° factura"
                                                :disabled="!newCanastaItem.product"
                                                autocomplete="off"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="3"
                                        >
                                            <v-text-field
                                                v-model="newCanastaItem.delivery_receipt"
                                                label="N° guia despacho"
                                                :disabled="!newCanastaItem.product"
                                                autocomplete="off"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="3"
                                        >
                                            <v-text-field
                                                v-model="newCanastaItem.merchandise_format"
                                                label="Formato mercadería"
                                                :disabled="!newCanastaItem.product"
                                                autocomplete="off"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="3"
                                        class="d-flex align-center"
                                        >
                                            <v-btn block @click="agregarFila" :disabled="!newCanastaItem.quantity || !newCanastaItem.product || !newCanastaItem.supplier_id || !newCanastaItem.price || newCanastaItem.price < 0 || !newCanastaItem.merchandise_format"  color="cyan" dark 
                                            >Agregar <v-icon right dark>mdi-plus</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                <br>
                                <v-textarea
                                class="mx-2"
                                label="Comentario"
                                rows="3"
                                prepend-icon="mdi-comment"
                                v-model="comment"
                                ></v-textarea>
                                <br>
                                <br>
                                <v-btn @click="finishIngresarItems" v-show="itemsOrden.length && itemsOrden.every(e => !!e.supplier && !!e.quantity)" small color="info" dark class="float-left"
                                ><v-icon left dark>mdi-format-list-checks</v-icon>Guardar</v-btn>
                                
                            </v-container>
                            
                        </v-card-text>
                    </v-card>
                </div>
            </div>
        </v-app>
        
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
export default {
    data(){
        return {
            dateFormat: 'D MMM',
            dateOne: '',
            dateTwo: '',
            range: {},
            headers: [
                {
                text: '#...',
                align: 'left',
                sortable: true,
                value: 'id',
                },
                { text: 'Referencia Orden de Compra', value: 'purchase_order_reference' },
                { text: 'Fecha de ingreso', value: 'ingreso', filter: this.filtroRangoFechas },
                { text: 'Estado', value: 'estado' },
                { text: 'Acciones', value: 'acciones' },
            ],
            tableData: [],
            search: '',
            selected: [],
            itemsOrden: [],
            modelGroup: null,
            headersOrderDetails: [
                {
                text: '#...',
                align: 'left',
                sortable: true,
                value: 'id',
                },
                { text: 'Referencia Pedido', value: 'referencia' },
                { text: 'Producto', value: 'name'},
                { text: 'SKU', value: 'sku'},
                { text: 'Cantidad', value: 'quantity'},
                { text: 'Fecha de ingreso', value: 'ingreso' },
            ],
            tableDataOrderDetails: [],
            searchOrderDetails: '',
            selectedOrderDetails: [],
            comment: '',
            mostrar: 0,
            tableDataProducts: [],
            searchProducts: '',
            selectedProducts: [],
            headersProducts: [
                {
                text: '#...',
                align: 'left',
                sortable: true,
                value: 'id',
                },
                { text: 'Item', value: 'nombre' },//
                { text: 'Proveedor', value: 'proveedor' },//
                
                { text: 'Cantidad disponible', value: 'cantidad' },
                //{ text: ''}
            ],
            detallesOrdenList: [],
            envasProducto: undefined,
            envasCantidad: 0,
            envasSku: '',
            envaUnit: {},
            date: new Date().toISOString().substr(0, 10),
            modal: false,
            urlPdf: '',
            suppliersList: [],
            newCanastaItem: {
                supplier_id: undefined,
                supplier_name: undefined,
                product: undefined,
                product_id: undefined,
                quantity: 0.0,
                invoice_number: '',
                delivery_receipt: '',
                price: 0,
                merchandise_format: undefined,
            },
        }
    },
    methods: {
        fetchDataPurchaseOrders()
        {
            var vm = this;
            this.axios({
                method: 'POST',
                url: 'inventory/purchase_orders',
                data: {
                    store: this.$store.state.passport.user.default_store_id
                }
            }).then( response => {
                vm.tableData = response.data;
                
            }).catch( error => {
                console.log(error);
            });
        },
        downloadPdfAComprar(id)
        {
            this.axios({
                url: 'inventory/purchase_order/'+id+'/pod_pdf',
                method: 'GET'
            }).then( response => {
                window.open(response.data, "_blank");
            }).catch( error => {
                console.log(error);
            });
        },
        downloadPdfCompras(id)
        {
            this.axios({
                url: 'inventory/purchase_order/'+id+'/pop_pdf',
                method: 'GET'
            }).then( response => {
                window.open(response.data, "_blank");
            }).catch( error => {
                console.log(error);
            });
        },
        filtroRangoFechas(value, search, item){
            console.log('item', item);
            console.log('value', value);
            if( !this.dateOne || !this.dateTwo ){
                return true;
            }else{
                if( this.$moment(item.created_at).isSameOrAfter(this.$moment(this.dateOne)) && this.$moment(item.created_at).isSameOrBefore(this.$moment(this.dateTwo)) ){
                    return true;
                }else{
                    return false;
                }
            }
        },
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        eliminarFila(item){
            const index = this.itemsOrden.indexOf(item);
            this.itemsOrden.splice(index,1);
        },
        startIngresarItems()
        {
            var vm = this;
            vm.axios({
                url: 'inventory/fetch_purchase_order_details',
                method: 'POST',
                data: {
                    purchase_order: this.selected[0].id,
                    store: this.$store.state.passport.user.default_store_id
                }
            }).then( response2 => {
                vm.itemsOrden = [];
                vm.urlPdf = response2.data.pdf;
                response2.data.tableData.forEach(element => {
                    let purchased_quantity = 0.0;

                    if(element.basket.length == 0){
                        vm.itemsOrden.push({
                            id: element.id,
                            unit: element.unit.name,
                            formato: parseFloat(element.formato.quantity).toFixed(2)+' '+element.unit.name,
                            supplier: undefined,
                            name: element.name,
                            quantity: parseFloat(0),
                            required: element.required,
                            price: undefined,
                            invoice_number: '',
                            delivery_receipt: '',
                            merchandise_format: '',
                        });
                    }
                    if(element.basket.length > 0){
                        element.basket.forEach( compra => {
                            vm.itemsOrden.push({
                                id: element.id,
                                unit: element.unit.name,
                                formato: parseFloat(element.formato.quantity).toFixed(2)+' '+element.unit.name,
                                supplier: compra.supplier_id,
                                name: element.name,
                                quantity: compra.purchased_quantity,
                                required: element.required,
                                price: parseInt(compra.price),
                                invoice_number: compra.invoice_number,
                                delivery_receipt: compra.delivery_receipt,
                                merchandise_format: compra.merchandise_format,
                            });
                        });
                    }
                    
                    /*
                    vm.itemsOrden.push({
                        basket: element.basket,
                        id: element.id,
                        //left: element.required,
                        left: parseFloat(element.required) - purchased_quantity,
                        name: element.name,
                        //purchased_quantity: 0,
                        purchased_quantity: purchased_quantity,
                        required_quantity: element.required,
                        unit: element.unit.name,
                        formato: parseFloat(element.formato.quantity).toFixed(2)+' '+element.unit.name
                    });
                    */
                });
                vm.mostrar = 1;
            }).catch( error2 => {
                console.log(error2);
            });
        },
        agregarFila(){
            this.itemsOrden.push({
                id: this.newCanastaItem.product.id,
                unit: this.newCanastaItem.product.unit,
                formato: this.newCanastaItem.product.formato,
                supplier: this.newCanastaItem.supplier_id.value,
                name: this.newCanastaItem.product.name,
                quantity: parseFloat(this.newCanastaItem.quantity),
                required: this.newCanastaItem.product.required,
                price: parseFloat(this.newCanastaItem.price),
                invoice_number: this.newCanastaItem.invoice_number,
                delivery_receipt: this.newCanastaItem.delivery_receipt,
                merchandise_format: this.newCanastaItem.merchandise_format,
            });
            this.newCanastaItem.supplier_id = undefined;
            this.newCanastaItem.supplier_name = undefined;
            this.newCanastaItem.product = undefined;
            this.newCanastaItem.product_id = undefined;
            this.newCanastaItem.quantity = 0.0;
            this.newCanastaItem.invoice_number = '';
            this.newCanastaItem.delivery_receipt = '';
            this.newCanastaItem.price = 0;
            this.newCanastaItem.merchandise_format = undefined;  
        },
        finishIngresarItems()
        {
            let itemsAgrupadosById = {};
            this.itemsOrden.forEach(item => {
                if(item.quantity > 0 && !!item.supplier && !!item.price){
                    if(!Array.isArray(itemsAgrupadosById[item.id])){
                        itemsAgrupadosById[item.id] = [];
                    }
                    itemsAgrupadosById[item.id].push(item);
                }
            });
            let arreglo = [];
            Object.keys(itemsAgrupadosById).forEach( property => {
                arreglo.push({ 
                    id: property,
                    basket: itemsAgrupadosById[property].map(elem => {
                        if(parseFloat(elem.quantity) > 0.0){
                            return ({
                                price: elem.price,
                                invoice_number: elem.invoice_number,
                                delivery_receipt: elem.delivery_receipt,
                                merchandise_format: elem.merchandise_format,
                                purchased_quantity: parseFloat(elem.quantity),
                                supplier_id: elem.supplier
                            })
                        }
                    }),
                    unit: itemsAgrupadosById[property][0].unit
                });
            });
            var vm = this;
            this.axios({
                url: 'inventory/finish_order_purchase',
                method: 'POST',
                data: {
                    items: arreglo,
                    comment: this.comment,
                }
            }).then( response => {
                vm.itemsOrden = [];
                vm.selected = [];
                vm.fetchDataPurchaseOrders();
            }).catch( error => {
                console.log(error);
            });
        },
        meTocaste(item)
        {
            console.log('ha sido tocado: ',item);
            const index = this.itemsOrden.indexOf(item)
            console.log({item, index});
            this.$emit('openDialogCompletadoDelItem', {item, index});
        },
        recibirCanasta(data)
        {
            console.log('recibi: ', data);
            // Actualizando data del item desde el componente hijo dialog
            this.itemsOrden[data.index].purchased_quantity = parseFloat(data.total_quantity);
            this.itemsOrden[data.index].left = this.itemsOrden[data.index].required_quantity - data.total_quantity;
            this.itemsOrden[data.index].basket = data.basket;
        },
    },
    mounted()
    {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Ingresar productos", route: "/compras/ordenes" },
        ]);
        this.fetchDataPurchaseOrders();
        var vm = this;
        this.axios({
            url: 'inventory/suppliers_data_list',
            method: 'POST',
            data: {
                store: this.$store.state.passport.user.default_store_id
            }
        }).then( response => {
            vm.suppliersList = response.data;
        }).catch( error => {
            console.log(error);
        });
    }
}
</script>